.section-michellese {
    .michellese {
        &-list {
            list-style: none;
            margin: 0;
            padding: 0;
            z-index: 1;
        }

        &-item {
            blockquote {
                margin: 0;
                padding: 0 30px;
                color: $color-vanilla-powder;
                font-family: 'Benzin', sans-serif;

                font-weight: 500;

                p {
                    margin: 0;
                }

                footer  {
                    margin-top: 10px;
                    font-family: 'Bebas Neue Pro', sans-serif;
                    font-size: rem(32);
                    line-height: lh(32, 32);
                    font-weight: 500;
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        .michellese {
            &-item {
                blockquote {
                    font-size: rem(26);
                    line-height: lh(26, 38);

                    img {
                        height: 30px;
                    }
                }

                footer  {
                    font-size: rem(20);
                    line-height: lh(20, 20);
                }
            }
        }
    }

    @include media-breakpoint-up(md) {
        .michellese {
            &-item {

                blockquote {
                    font-size: rem(46);
                    line-height: lh(46, 68);
                }

                footer  {
                    font-size: rem(32);
                    line-height: lh(32, 32);
                }
            }
        }
    }

    @include media-breakpoint-down(xl) {
        .michellese {
            &-item {
                margin-top: 70px;
            }
        }
    }

    @include media-breakpoint-up(xl) {
        .michellese {
            &-item {
                max-width: 690px;
                margin-top: 30px;

                &:nth-child(even) {
                    margin-left: auto;
                }
            }
        }
    }
}