.main-header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;

    #mobileMenuTrigger {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 48px;
        height: 48px;
        padding: 12px;
        border: none;
        background-color: $color-brown;
        cursor: pointer;

        span {
            display: block;
            position: relative;
            top: 0;
            width: 100%;
            height: 3px;
            margin: 2px auto;
            transition: top, transform 0.3s 0s;
            background-color: $color-white;
            transition-duration: 0s;

            &:first-child, &:last-child {
                transition-duration: 0.2s;
                transition-delay: 0.2s, 0s;
            }
        }

        @at-root .mobileMenuActive & {
            span {
                background-color: $color-white;
                transition-delay: 0.2s;

                &:first-child, &:last-child {
                    transition-delay: 0s, 0.2s;
                }

                &:first-child {
                    top: 7px;
                    transform: rotate(45deg);

                    + span {
                        background-color: rgba(0, 0, 0, 0);
                    }
                }

                &:last-child {
                    top: -7px;
                    transform: rotate(-45deg);
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        display: block;
    }

    @include media-breakpoint-up(md) {
        display: none;
    }
}