.section-preview {
    .content {
        color: $color-vanilla-powder;
    }

    .section-title {
        color: $color-vanilla-powder;
        font-size: rem(54);
        line-height: lh(54, 54);
        font-weight: 500;
        text-transform: capitalize;

        span {
            margin-top: 10px;
            font-size: rem(42);
            line-height: lh(42, 42);
        }
    }

    .bottom-info {
        position: relative;
        align-self: flex-end;
        text-align: center;

        &:before {
            content: '';
            display: block;
            width: 1px;
            height: 68px;
            background-color: #d9d9d9;
            z-index: 1;
        }
    }

    p {
        font-size: rem(22);
        line-height: lh(22, 22);
        text-transform: uppercase;
        letter-spacing: 6px;
    }


    @include media-breakpoint-down(md) {
        .content {
            padding-bottom: 100px;
            align-self: flex-end;
        }

        .bottom-info {
            &:before {
                margin: 6vh auto;
            }
        }
    }

    @include media-breakpoint-up(md) {
        .content {
            padding-top: 16vh;
        }

        .bottom-info {
            &:before {
                margin: 6vh auto;
            }
        }
    }
}