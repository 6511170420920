.section-memories {
    .memories {
        &-list {
            max-width: 800px;
            margin: 0 auto;
            padding: 0 25px;
            list-style: none;
        }

        &-item {
            blockquote {
                margin: 70px 0 0 0;
                font-family: 'Benzin', sans-serif;
                font-weight: 500;

                footer  {
                    margin-top: 15px;
                    font-family: 'Bebas Neue Pro Book', sans-serif;
                    font-weight: normal;
                }
            }
        }
    }

    .description {
        .openShareStoryBtn {
            position: absolute;
            top: 50%;
            right: 120px;
            transform: translateY(-50%);
            z-index: 1;
        }
    }

    @include media-breakpoint-down(md) {
        .memories {
            &-item {
                blockquote {
                    font-size: rem(20);
                    line-height: lh(20, 28);

                    footer  {
                        font-size: rem(22);
                        line-height: lh(22, 24);
                    }
                }
            }
        }
    }

    @include media-breakpoint-up(md) {
        .memories {
            &-item {
                blockquote {
                    font-size: rem(28);
                    line-height: lh(28, 42);

                    footer  {
                        font-size: rem(26);
                        line-height: lh(26, 28);
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(lg) {
        .panel-bottom {
            margin-top: 50vh;
        }
    }

    @include media-breakpoint-between(lg, xxl) {
        .panel-bottom {
            margin-top: 120vh;
        }
    }

    @include media-breakpoint-down(xxl) {
        .description {
            .openShareStoryBtn {
                display: none;
            }
        }

        .panel-bottom {
            display: block;
        }
    }

    @include media-breakpoint-up(xxl) {

    }
}