$color-white: #fff;
$color-black: #000;
$color-brown: #251605;
$color-wild-bill-brown: #795845;
$color-brown-light: #937666;
$color-tongue: #ce8f8f;
$color-caboose: #a7a4a1;
$color-dolphin-dream: #696d76;
$color-vanilla-powder: #faf3dd;

$color-error: #d85d5d;
