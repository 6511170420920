*,
*::before,
*::after {
    box-sizing: border-box; // 1
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: 'Bebas Neue Pro', sans-serif;
    font-weight: normal;
    font-style: normal;
    background-color: #000;
    color: #fff;

    &.contentLocked {
        overflow: hidden;
    }
}

html, body {
    height: 100%;
}

[hidden] {
    display: none !important;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    color: #fff;
}

.section-title {
    font-family: 'Benzin', sans-serif;
    font-weight: bold;
    text-transform: uppercase;

    span {
        display: block;
        font-family: 'Bebas Neue Pro Book', sans-serif;
        font-weight: normal;
    }

    @at-root .section-inner & {
        color: $color-brown;
        font-size: 9vw;
        line-height: 1;
    }

    @include media-breakpoint-down(md) {
        //font-size: rem(62);
        //line-height: lh(62, 68);
    }

    @include media-breakpoint-up(md) {
        //font-size: rem(72);
        //line-height: lh(72, 72);
    }

}

.text {
    &-hidden {
        display: none;
    }

    &-uppercase {
        text-transform: uppercase;
    }

    &-color {
        &-white {
            color: $color-white;
        }
    }

    // Text Alignment
    &-left {
        text-align: left;
    }

    &-center {
        text-align: center;
    }

    &-right {
        text-align: right;
    }
}

//Custom scroll
* {
    &::-webkit-scrollbar {
        width: 10px;

        &-track {
            background-color: #d9d9d9;
        }

        &-thumb {
            background-color: darken($color-brown, 5%);

            &:hover {
                background-color: lighten($color-brown, 3%);
            }
        }
    }
}

/**
 * Disable smooth scrolling when users have prefers-reduced-motion enabled
 */
@media screen and (prefers-reduced-motion: reduce) {
    html {
        scroll-behavior: auto;
    }
}