.section-main {
    color: #fff;

    .section-title {
        color: $color-vanilla-powder;
        font-size: 7vw;
        line-height: 1;
        text-transform: capitalize;

        span {
            font-size: 5vw;
            margin-top: 3vh;
        }
    }


    @include media-breakpoint-down(md) {

    }

    @include media-breakpoint-up(md) {

    }
}