// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

// scss-docs-start grid-breakpoints
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1400px,
        xxxl: 1600px
) !default;
// scss-docs-end grid-breakpoints

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

// scss-docs-start container-max-widths
$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1320px
) !default;
// scss-docs-end container-max-widths

// Colors
$link-hover: #e99f02;
$color-brown-light: #b5a180;
$color-gray: #363636;
$color-gray-light: #e3e3e3;
$color-gray-dark: #252525;
$color-white: #fff;
$color-black: #000;

// Wave margin compensation
$wave-margin: 5vw;
$wave-margin-mob: 6vw;
