.section-music {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;

    .music-grid {
        display: grid;
        grid-auto-columns: auto;

        &-layer {
            grid-row-start: 1;
            grid-column-start: 1;
        }
    }

    .section-title {
        color: #795845;
        font-size: 10vw;
        line-height: 1;
    }

    .player {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: $color-vanilla-powder;
    }

    .details {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin-top: 25px;
    }

    //.track-art {
    //    margin: 25px;
    //    height: 250px;
    //    width: 250px;
    //    background-image: URL("https://source.unsplash.com/Qrspubmx6kE/640x360");
    //    background-size: cover;
    //    background-position: center;
    //    border-radius: 15%;
    //}

    /* Changing the font sizes to suitable ones */
    .now-playing {
        font-size: 1rem;
        letter-spacing: 3px;
    }

    .track-artist {
        font-size: rem(52);
        line-height: lh(52, 52);
    }

    .track-name {
        margin-top: 30px;
    }

    /* Using flex with the row direction to
       align items in a horizontal direction */
    .buttons {
        display: flex;
        gap: 8vw;
        align-items: center;
        margin-top: 10px;
    }

    .playpause-track,
    .prev-track,
    .next-track {
        padding: 10px;
        opacity: 0.8;
        cursor: pointer;
        transition: opacity .2s ease-out;
    }

    /* Change the opacity when mouse is hovered */
    .playpause-track:hover,
    .prev-track:hover,
    .next-track:hover {
        opacity: 1.0;
    }

    /* Define the slider width so that it scales properly */
    .slider_container {
        width: 75%;
        max-width: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    /* Modify the appearance of the slider */
    .seek_slider, .volume_slider {
        appearance: none;
        height: 5px;
        background-color: $color-brown-light;
        opacity: 0.7;
        transition: opacity .2s;
        border-radius: 3px;
    }

    /* Modify the appearance of the slider thumb */
    .seek_slider::-webkit-slider-thumb,
    .volume_slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 15px;
        height: 15px;
        background: white;
        cursor: pointer;
        border-radius: 50%;
    }

    /* Change the opacity when mouse is hovered */
    .seek_slider:hover,
    .volume_slider:hover {
        opacity: 1.0;
    }

    .seek_slider {
        width: 60%;
    }

    .volume_slider {
        width: 30%;
    }

    .current-time,
    .total-duration {
        padding: 10px;
    }

    .icon-volume {
        display: block;
        width: 24px;
        height: 24px;
        margin: 0 10px;
    }

    @include media-breakpoint-down(md) {
        .content,
        .music-grid {
            height: 100%;
        }

        .description {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 25vh;
        }

        .playpause-track {
            svg {
                width: 74px;
                height: 62px;
            }
        }

        .track-name {
            font-size: rem(26);
            line-height: lh(26, 28);
        }

        .prev-track,
        .next-track {
            svg {
                width: 43px;
                height: 38px;
            }
        }
    }

    @include media-breakpoint-up(md) {
        padding-left: 120px;

        .description {
            align-self: center;
        }

        .track-name {
            font-size: rem(52);
            line-height: lh(52, 52);
        }

        .playpause-track {
            svg {
                width: 78px;
                height: 92px;
            }
        }

        .prev-track,
        .next-track {
            svg {
                width: 54px;
                height: 48px;
            }
        }
    }
}