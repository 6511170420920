.main-menu {
    width: 100%;
    margin-bottom: 6vh;

    ul {
        display: flex;
        width: 100%;
        position: relative;
        margin: 0;
        padding: 0;
        list-style: none;
    }

    a {
        display: flex;
        padding: 4vh 0;
        font-family: 'Bebas Neue Pro', sans-serif;
        font-weight: bold;
        letter-spacing: 5px;
        text-transform: uppercase;
        text-decoration: none;
        transition: all .2s ease-out;
        width: 100%;
    }

    @include media-breakpoint-down(md) {
        ul {
            flex-direction: column-reverse;
        }

        li {
            &:nth-child(3) {
                a {
                    border-color: $color-dolphin-dream;
                }
            }

            &:nth-child(2) {
                a {
                    border-color: $color-tongue;
                }
            }

            &:nth-child(1) {
                a {
                    border-color: $color-brown-light;
                }
            }
        }

        a {
            padding-left: 15px;
            color: #fff;
            font-size: rem(42);
            line-height: lh(42, 42);
            border-left: 8px solid transparent;
        }
    }

    @include media-breakpoint-up(md) {
        writing-mode: vertical-rl;
        transform: rotate(-180deg);

        li {
            min-height: calc(100vh /4);
            text-align: center;
            overflow: hidden;

            &:nth-child(3) {
                a {
                    background-color: $color-dolphin-dream;
                }

                &:hover {
                    a {
                        background-color: darken($color-dolphin-dream, 5%);
                    }
                }
            }

            &:nth-child(2) {
                a {
                    background-color: $color-tongue;
                }

                &:hover {
                    a {
                        background-color: darken($color-tongue, 5%);
                    }
                }
            }

            &:nth-child(1) {
                a {
                    background-color: $color-brown-light;
                }

                &:hover {
                    a {
                        background-color: darken($color-brown-light, 5%);
                    }
                }
            }
        }

        a {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: #000;
            font-size: rem(22);
            line-height: lh(22, 22);

            &:hover {
                transform: scale(1.2);
            }
        }
    }
}