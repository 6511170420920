.section-photos {
    .gallery {
        gap: 8vw;

        img {
            display: block;
            width: 100%;
            margin-bottom: 8vw;
            object-fit: cover;
        }
    }



    @include media-breakpoint-only(md) {
        .gallery {
            columns: 2;
        }
    }

    @include media-breakpoint-up(lg) {
        .gallery {
            columns: 3;
        }
    }
}