@font-face {
    font-family: 'Bebas Neue Pro';
    src: local('Bebas Neue Pro Regular'), local('BebasNeuePro-Regular'),
    url('../fonts/BebasNeuePro-Regular.woff2') format('woff2'),
    url('../fonts/BebasNeuePro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bebas Neue Pro';
    src: local('Bebas Neue Pro Light'), local('BebasNeuePro-Light'),
    url('../fonts/BebasNeuePro-Light.woff2') format('woff2'),
    url('../fonts/BebasNeuePro-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bebas Neue Pro';
    src: local('Bebas Neue Pro Bold'), local('BebasNeuePro-Bold'),
    url('../fonts/BebasNeuePro-Bold.woff2') format('woff2'),
    url('../fonts/BebasNeuePro-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bebas Neue Pro';
    src: local('Bebas Neue Pro Middle'), local('BebasNeuePro-Middle'),
    url('../fonts/BebasNeuePro-Middle.woff2') format('woff2'),
    url('../fonts/BebasNeuePro-Middle.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Bebas Neue Pro Book';
    src: local('Bebas Neue Pro Book'), local('BebasNeuePro-Book'),
    url('../fonts/BebasNeuePro-Book.woff2') format('woff2'),
    url('../fonts/BebasNeuePro-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Benzin';
    src: local('Benzin Medium'), local('Benzin-Medium'),
    url('../fonts/Benzin-Medium.woff2') format('woff2'),
    url('../fonts/Benzin-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Benzin';
    src: local('Benzin Bold'), local('Benzin-Bold'),
    url('../fonts/Benzin-Bold.woff2') format('woff2'),
    url('../fonts/Benzin-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}