.social-media {
    .social-media-list {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            position: relative;
        }

        a {
            display: block;
            padding: 5px;
            text-decoration: none;

            &:hover {
                img, svg {
                    transform: scale(1.1);
                }

                svg {
                    fill: $color-white;
                }
            }
        }

        img, svg {
            display: block;
            width: 38px;
            height: 38px;
            transition: all .2s ease-out;
        }
    }

    @include media-breakpoint-down(md) {
        padding-left: (15px + 8px);

        .social-media-list {
            svg {
                fill: $color-white;
            }
        }
    }

    @include media-breakpoint-up(md) {
        .social-media-list {
            align-items: center;

            svg {
                fill: $color-vanilla-powder;
            }
        }
    }
}