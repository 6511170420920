.bg {
    // Gradients
    &-gradient {
        &-orange {
            background: linear-gradient(180.00deg, rgb(243, 118, 88),rgb(249, 170, 78) 100%);
        }
    }

    // One Color
    &-color {
        &-black {
            background-color: $color-black;
        }

        &-brown {
            background-color: $color-brown;

            &-light {
                background-color: $color-brown-light;
            }


        }

        &-vanilla {
            background-color: $color-vanilla-powder;
        }

        &-gray {
            background-color: $color-dolphin-dream;

            &-light {
                background-color: $color-caboose;
            }
        }

        &-tongue {
            background-color: $color-tongue;
        }
    }
}