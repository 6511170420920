.btn {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 130px;
    padding: 8px 16px;
    color: #fff;
    font-size: rem(22);
    line-height: lh(22, 22);
    letter-spacing: 5px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    //white-space: nowrap;
    text-decoration: none;
    border: none;
    cursor: pointer;
    background: transparent;
    transition: all .2s ease-out;

    &:hover {
        text-decoration: none;
    }

    &:focus {
        text-decoration: none;
    }

    &:disabled {
        opacity: .4;
        cursor: not-allowed;
        pointer-events: none;
    }

    &-block {
        width: 100%;
    }

    &-border {
        &-white {
            color: #faf3dd;
            border: 4px solid #fff;

            &:hover {
                color: $color-wild-bill-brown;
                background-color: #fff;
                border-color: darken($color-wild-bill-brown, 10%);
            }
        }

        &-brown {
            color: $color-wild-bill-brown;
            border: 4px solid $color-wild-bill-brown;

            &:hover {
                color: $color-vanilla-powder;
                background-color: darken($color-wild-bill-brown, 10%);
            }
        }
    }

    &-text {
        &-brown {
            color: $color-wild-bill-brown;

            &:hover {
                color: $color-vanilla-powder;
                background-color: darken($color-wild-bill-brown, 10%);
            }
        }
    }

    &-img {
        padding: 8px 16px;
        border: none;
        color: #faf3dd;
        cursor: pointer;
        background: none;

        &-text {
            display: flex;
            font-size: rem(22);
            line-height: lh(22, 22);
            letter-spacing: 4px;
            font-weight: bold;
            text-transform: uppercase;
            text-align: center;
            text-decoration: none;
            border: 4px solid transparent;
            transition: all .2s ease-out;

            &:hover {
                border-color: #fff;

                img, svg {
                    transform: scale(1.1);
                }

                svg {
                    fill: #fff;
                }
            }

            img, svg {
                margin-right: 10px;
                transition: all .3s ease;
            }
        }

        &-border {
            &-white {
                border: 4px solid #fff;

                &:hover {
                    color: $color-wild-bill-brown;
                    background-color: #fff;
                    border-color: darken($color-wild-bill-brown, 10%);
                }
            }
        }

        img, svg {
            display: block;
            width: 28px;
            height: 21px;
            fill: currentColor;
            transition: all .1s ease;
        }
    }
}

.btn-close {
    position: absolute;
    padding: 15px;
    text-decoration: none;
    border: none;
    cursor: pointer;
    background: transparent;

    span {
        position: relative;
        display: block;
        width: 36px;
        height: 4px;
        background-color: $color-brown-light;

        &:first-child {
            transform: rotate(45deg);
        }

        &:last-child {
            top: -4px;
            transform: rotate(-45deg);
        }
    }

    @include media-breakpoint-down(xl) {
        top: 20px;
        right: 0;
    }


    @include media-breakpoint-up(xl) {
        top: 40px;
        right: 40px;
    }
}