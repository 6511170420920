@include media-breakpoint-down(md) {
    .hide-on-mobile {
        display: none;
    }
}

@include media-breakpoint-up(md) {
    .show-on-mobile {
        display: none;
    }
}