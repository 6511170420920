$container-width: 1600px;

#page-container {
    position: relative;
    min-height: 100%;

    .aside {
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 5;

        &-inner {
            position: sticky;
            top: 0;
        }
    }

    main {
        position: relative;
    }
}

.container {
    padding: 0 25px;
    max-width: $container-width;
    margin: 0 auto;
}

.section {
    position: relative;

    &:not(.section-fullscreen) {
        min-height: 100%;
    }

    &-grid {
        display: grid;
        grid-auto-columns: auto;

        &-bg-media {
            overflow: hidden;
            pointer-events: none;
        }

        &-bg-media,
        .content {
            grid-row-start: 1;
            grid-column-start: 1;
        }

        &-bg-media {
            img, video {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .content {
            z-index: 1;

            &-v-center {
                align-self: center;
            }

            &-v-end {
                align-self: flex-end;
            }

            &-h-center {
                justify-self: center;
            }
        }
    }

    .content {
        img {
            max-width: 100%;
        }
    }

    &-fullscreen {
        height: 100%;
    }

    &-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    &-inner {
        .content {
            padding: 70px 0;
        }

        .description {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            position: sticky;
            top: 0;
            z-index: -1;

            .section-title {
                width: 100%;
                text-align: center;
            }

            @include media-breakpoint-down(lg) {
                height: 25vh;
            }

            @include media-breakpoint-up(lg) {
                height: 100vh;
            }
        }
    }
}

.description {
    text-align: center;
}

.panel-top {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    z-index: 5;

    &-inner {
        display: flex;
        justify-content: space-between;
    }

    @include media-breakpoint-down(md) {
        &-inner {
            flex-direction: column;
        }

        .mobile-panel {
            position: fixed;
            width: 100%;
            z-index: 1;
            backdrop-filter: blur(1px);

            &-top {
                right: 0;
                display: flex;
                justify-content: flex-end;

                .btn-img {
                    border-color: transparent;
                }
            }

            &-bottom {
                left: 50%;
                bottom: 0;
                padding: 15px 0;
                transform: translateX(-50%);
            }
        }
    }

    @include media-breakpoint-up(md) {
        padding: 30px 0 30px 120px;
        backdrop-filter: blur(1px);
    }
}

@include media-breakpoint-down(md) {
    aside {
        position: fixed;
        display: none;

        @at-root .mobileMenuActive & {
            display: flex;
            flex-direction: column;
            justify-content: center;
            background-color: rgba(0, 0, 0, 0.7);
        }
    }
}

@include media-breakpoint-up(md) {
    aside {
        position: absolute;
        display: block;
        max-width: 120px;
    }

    .section-inner {
        padding-left: 120px;
    }
}