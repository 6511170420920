// Variables, Mixins, Functions
@import "helpers/variables";
@import "helpers/mixins";
@import "helpers/functions";

// CSS frameworks
@import "vendor/libs";

// Base
@import "base/colors";
@import "base/backgrounds";
@import "base/fonts";
@import "base/general";
@import "base/decor";
@import "base/layout";
@import "base/buttons";
@import "base/tables";
@import "base/forms/form-elements";

// Component
@import "../blocks/components/main-menu/main-menu";
@import "../blocks/components/animations/animations";
@import "../blocks/components/social-media/social-media";

// BEM-blocks
@import "../blocks/modules";

// Common sections

// PAGE HOME
@import "../blocks/pages/page-home/section-main/section-style";
@import "../blocks/pages/page-home/section-preview/section-style";
@import "../blocks/pages/page-home/section-michellese/section-style";
@import "../blocks/pages/page-home/section-memories/section-style";
@import "../blocks/pages/page-home/section-share-story/section-style";
@import "../blocks/pages/page-home/section-photos/section-style";
@import "../blocks/pages/page-home/section-music/section-style";
