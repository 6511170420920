.section-share-story {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;

    .container {
        display: flex;
        flex-direction: column;
    }

    .content,
    .container,
    form,
    .share-story-steps,
    .share-story-step,
    .share-story-grid {
        height: 100%;
    }

    .section-title {
        margin-bottom: 4vw;
        padding-bottom: 3vw;
        border-bottom: 1px solid #c6b5a1;
        color: $color-wild-bill-brown;
    }

    .share-story {
        flex: 1;
        position: relative;

        &-steps {
            .form-data {
                background-color: $color-wild-bill-brown;

                &-error {
                    background-color: $color-error;
                }
            }
        }

        &-step {
            &-title {
                color: $color-brown;
                font-family: 'Benzin', sans-serif;
                font-weight: 500;
            }

            .form-control {
                color: #faf3dd;
                font-weight: 300;
                border: none;
                background-color: transparent;

                &::placeholder {
                    color: #faf3dd;
                }

                &:focus, &:active {
                    outline: none;
                    background: transparent;
                }
            }
        }

        &-grid {
            display: flex;
            justify-content: space-between;
        }

        &-info {
            position: absolute;
            font-family: 'Benzin', sans-serif;
            font-weight: normal;
            color: #bdb6b2;

            &-active {
                color: $color-wild-bill-brown;
            }
        }
        
        &-nav {
            position: absolute;
            display: flex;
            gap: 12px;
        }
    }

    .thankYouMessage {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8vw;
        padding: 3vw 0;
        color: #000;
        font-family: 'Benzin', sans-serif;
        font-weight: 500;

        p {
            max-width: 420px;
        }
    }

    @include media-breakpoint-down(md) {
        .content {
            padding: 70px 0 30px;
        }

        .description {
            text-align: left;
        }

        .section-title {
            font-size: 10vw;
        }

        .share-story {
            &-steps {
                .form-data {
                    flex: 1;
                    max-height: 320px;
                    padding: 18px 18px 80px 18px;
                }
            }

            &-grid {
                flex-direction: column;
            }

            &-info {
                bottom: 340px;
                font-size: rem(22);
                line-height: lh(22, 22);
            }

            &-nav {
                right: 18px;
                bottom: 18px;
            }
        }

        .thankYouMessage {
            flex-direction: column;
            font-size: rem(26);
            line-height: lh(26, 38);
            text-align: center;

            img {
                margin: 8vw 0;
            }

            p {
                margin: 0;
            }
        }
    }

    @include media-breakpoint-up(md) {
        .content {
            padding: 70px 0;
        }

        .section-title {
            font-size: 7vw;
        }

        .share-story {
            &-steps {
                .form-data {
                    padding: 28px 28px 60px 28px;
                }
            }

            &-grid {
                gap: 6vw;

                &-item {
                    flex: 1;
                }
            }

            &-info {
                left: calc(50% - 70px);
                bottom: 0;
                transform: translateX(-50%);
                font-size: rem(42);
                line-height: lh(42, 52);
            }

            &-nav {
                right: 28px;
                bottom: 28px;
            }
        }

        .thankYouMessage {
            font-size: rem(36);
            line-height: lh(36, 48);
        }
    }

    @include media-breakpoint-down(lg) {
        .share-story {
            &-step {
                &-title {
                    font-size: rem(26);
                    line-height: lh(26, 36);
                }

                .form-control {
                    font-size: rem(36);
                    line-height: lh(36, 40);
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .share-story {
            &-step {
                &-title {
                    font-size: rem(52);
                    line-height: lh(52, 64);
                }

                .form-control {
                    font-size: rem(58);
                    line-height: lh(58, 58);
                }
            }
        }
    }
}